import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {addRatehawkHotels, checkUploadedHotels, updateRatehawkHotels} from '../../../store/actions/other';


const AddHotels = ({ onAddHotels, onUpdateHotels, onCheckUploadedHotels }) => {
  const fileReader = new FileReader();
  const [hotels, setHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const startUploading = async () => {
    const result = hotels.map((data) => ({
      hotelName: data.hotelName,
      tags: {
        specialization: data['Specialization tags'],
        location: [data['Part of the world'], data.Country, data.Region, data.City].join(', '),
      },
      url: data['Link to hotel Profile on Rate Hawk'],
    }));
    try {
      setIsLoading(true);
      await onAddHotels({ result });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const startUpdating = async () => {
    const result = hotels.map((data) => ({
      hotelName: data.HotelName,
      url: data.RateHawkLink,
    }));

    try {
      setIsLoading(true);
      await onUpdateHotels({ result });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  fileReader.onload = (event) => {
    const jsonRes = JSON.parse(event.target.result);

    if (!jsonRes) return;

    setHotels(jsonRes);
  };

  const onChangeHandler = (event) => {
    fileReader.readAsText(event.target.files[0]);
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid container item xs={12}>
        <Grid item>
          <Button
            variant="contained"
            component="label"
            color="primary"
          >
            Add ratehawk hotels
            <input
              type="file"
              onChange={onChangeHandler}
              style={{ display: 'none' }}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            disabled={!hotels || !hotels.length || isLoading}
            onClick={startUploading}
          >
            Upload ratehawk hotels
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={!hotels || !hotels.length || isLoading}
            onClick={startUpdating}
          >
            Update local hotels
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3} style={{ marginTop: 50 }}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={onCheckUploadedHotels}
          >
            Check not uploaded hotels
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null, {
  onAddHotels: addRatehawkHotels,
  onUpdateHotels: updateRatehawkHotels,
  onCheckUploadedHotels: checkUploadedHotels,
})(AddHotels);
