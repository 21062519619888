import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DateRangePicker from '../../../components/DateRangePicker';
import { format, getHotelContactReports, getNewsletterStats } from '../../../store/actions/other';

const NewsletterStats = ({ onGetNewsletterStats, onGetHotelContactReports }) => {
  const [newsletterList, setNewsletterList] = React.useState([]);
  const [dates, setDates] = React.useState([
    moment().subtract(2, 'month').format(format),
    moment().endOf('day').format(format),
  ]);

  const selectData = async () => {
    if (dates && dates.length) {
      const newslettersData = await onGetNewsletterStats(dates);
      if (newslettersData) {
        setNewsletterList(newslettersData);
      }
    }
  };

  const getReport = () => {
    onGetHotelContactReports(dates);
  };

  React.useEffect(() => {
    selectData();
  }, [moment(dates[0]).diff(moment(dates[1]), 'day')]);

  return (
    <Grid container justify="center" alignItems="flex-start" className="newsletter-stat">
      <Grid container spacing={3} justify="center" alignItems="center" style={{ display: 'flex', marginBottom: 20, maxWidth: 900 }}>
        <Grid item xs>
          <DateRangePicker
            label="Dates"
            defaultValue={dates}
            value={dates}
            labelFormat="DD MMMM YYYY"
            onChange={setDates}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs>
          <Typography>
            Hotel contacts report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={getReport}
          >
            DOWNLOAD REPORT
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="space-around">
        {newsletterList.map(({
          topic, subject, stat, targetAgents,
        }) => {
          const isSingle = typeof topic === 'string';
          return (
            <ExpansionPanel className="newsletter-stat__panel" key={`${subject}_${Math.random()}`}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className="newsletter-stat__panel-summary"
              >
                <Typography align="left" variant="subtitle1">
                  {subject}
                  {' '}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="newsletter-stat__detail">
                <Grid container className="newsletter-stat__panel">
                  <Card className="newsletter-stat__panel__card">
                    <Typography>
                      Topics
                    </Typography>
                    <Typography align="left" variant="caption">
                      {isSingle ? (
                        <span className="tag">{topic}</span>
                      ) : (
                        <span className="tag">{topic.join(', ')}</span>
                      )}
                    </Typography>
                  </Card>
                  <Card className="newsletter-stat__panel__card">
                    <Typography>
                      Target emails
                    </Typography>
                    <Typography align="left" variant="caption">
                      {targetAgents.map((agent) => (agent.contacts ? agent.contacts.email : '')).join(', ')}
                    </Typography>
                  </Card>
                </Grid>
                <Grid container className="newsletter-stat__panel">
                  {stat && stat.length ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Agent name</TableCell>
                          <TableCell align="right">Open count</TableCell>
                          <TableCell align="right">First click</TableCell>
                          <TableCell align="right">Last click</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stat.map((s) => (
                          <TableRow key={s.id}>
                            <TableCell component="th" scope="row">
                              {s.Agent.name}
                            </TableCell>
                            <TableCell align="right">{s.openCount}</TableCell>
                            <TableCell align="right">
                              {moment(s.createdAt).format('YYYY MMM DD (hh:mm)')}
                            </TableCell>
                            <TableCell align="right">
                              {moment(s.updatedAt).format('YYYY MMM DD (hh:mm)')}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : <Typography>There is no visits yet</Typography>}
                </Grid>

              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ hotels: { hotelsList } }) => ({ hotelsList }),
  {
    onGetNewsletterStats: getNewsletterStats,
    onGetHotelContactReports: getHotelContactReports,
  },
)(NewsletterStats);


NewsletterStats.propTypes = {
  onGetNewsletterStats: PropTypes.func.isRequired,
  onGetHotelContactReports: PropTypes.func.isRequired,
};
