// eslint-disable react/jsx-props-no-spreading

import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { getHotels } from '../../../store/actions/hotels';
import { getAgents } from '../../../store/actions/agents';
import SendMessageForm from '../components/SendMessageForm';
import { sendTelegramMessage } from '../../../store/actions/other';
import TelegramRequestsList from "../components/TelegramRequestsList";

const columns = [
  { id: 'Agent.name', label: 'Agent name', minWidth: 170 },
  { id: 'Hotel.name', label: 'Hotel name', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 100 },
  {
    id: 'updatedAt', label: 'Updated At', minWidth: 140, type: 'date-minutes',
  },
  { id: 'selectedRoomName', label: 'Room name', minWidth: 170 },
  {
    id: 'checkin', label: 'Checkin', minWidth: 120, type: 'date',
  },
  {
    id: 'checkout', label: 'Checkout', minWidth: 120, type: 'date',
  },
  { id: 'meal', label: 'Meal type', minWidth: 100 },
  { id: 'price', label: 'Price', minWidth: 100 },
  {
    id: 'initialPrice', label: 'Initial Price', minWidth: 100, otherId: 'price',
  },
  { id: 'markup', label: 'Markup', minWidth: 100 },

];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
    <Box p={3}>
      <Typography>{children}</Typography>
    </Box>
    )}
  </div>
);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});


const Telegram = ({
  hotelsList = [], agentsList = [], onGetHotels, onGetAgents, onSendTelegramMessage,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSendMessage = (data) => {
    onSendTelegramMessage({
      ...data,
      hotels: data.hotels.map((hotel) => hotel.id),
      agents: data.agents.map((agent) => agent.id),
    });
  };
  React.useEffect(() => {
    onGetHotels();
    onGetAgents();
  }, []);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <AppBar position="static" color="inherit">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Send message" {...a11yProps(0)} />
              <Tab label="Telegram requests" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <SendMessageForm
              hotels={hotelsList.map(({ name, id }) => ({ name, id }))}
              agents={agentsList.map(({ name, id }) => ({ name, id }))}
              initialValues={{
                hotels: [],
                agents: [],
              }}
              onSubmit={handleSendMessage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TelegramRequestsList/>
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ hotels: { hotelsList }, agents: { agentsList } }) => ({ hotelsList, agentsList }),
  {
    onGetHotels: getHotels,
    onGetAgents: getAgents,
    onSendTelegramMessage: sendTelegramMessage,
  },
)(Telegram);

Telegram.propTypes = {
  onCancelBooking: PropTypes.func.isRequired,
  bookingList: PropTypes.array.isRequired,
  onGetBookings: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
