import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
  SIGN_IN,
  DASHBOARD,
  CREATE_CONFIRM_ROUTE,
  HOTELS,
  AGENTS,
  HOTEL_GROUPS,
  REPORTS,
  CREATE_USER,
  BOOKING,
  BOOKING_SCREEN,
  ADD_HOTELS,
  TELEGRAM,
  WEBINAR_REQUESTS,
} from './routes';
import SignIn from '../screens/auth/containers/SignIn';
import RestService from '../utils/restService';
import CreateConfirm from '../screens/confirm/CreateConfirm';
import { COOKIE_NAME } from '../store/types';
import Dashboard from '../screens/dashboard/containers/Dashboard';
import { loginSuccess } from '../store/actions/auth';
import NotFound from '../screens/not-found/containers/Dashboard';
import MainLayout from '../components/MainLayout';
import Hotels from '../screens/hotels/containers/Hotels';
import HotelsGroups from '../screens/hotel-groups/containers/HotelGroups';
import HotelGroupScreen from '../screens/hotel-groups/containers/HotelGroupScreen';
import Agents from '../screens/agents/containers/Agents';
import NewsletterStats from '../screens/Reports/containers/NewsletterStats';
import UserCreate from '../screens/User/containers/UserCreate';
import Booking from '../screens/Booking/containers/Booking';
import SingleBooking from '../screens/Booking/containers/SingleBooking';
import AddHotels from '../screens/AddHotels/containers/AddHotels';
import Telegram from '../screens/Telegram/containers/Telegram';
import WebinarList from '../screens/Webinar/containers/WebinarList';
import WebinarPage from "../screens/Webinar/containers/WebinarPage";

export const requestHandler = new RestService();

const Routes = ({
  auth, onLoginSuccess, location, history,
}) => {
  const criteria = auth && auth.token ? auth.token.length : 0;
  const [loaded, setLoaded] = React.useState(false);
  useEffect(() => {
    const token = Cookies.get(COOKIE_NAME);
    if (token) {
      requestHandler.updateProps({ token });
      onLoginSuccess(token);
    }
    setLoaded(true);
  }, [criteria]);

  if (!loaded) return null;

  const title = location.pathname.replace('-', ' ').split('/').pop().toUpperCase();

  const PrivateRoutes = () => (
    <MainLayout title={title} history={history} pathname={location.pathname}>
      <Switch>
        <Redirect exact from="/" to={DASHBOARD} />
        <Redirect exact from={SIGN_IN} to={DASHBOARD} />
        <Route path={DASHBOARD} component={Dashboard} />
        <Route path={AGENTS} component={Agents} />
        <Route path={HOTELS} component={Hotels} />
        <Route path={REPORTS} component={NewsletterStats} />
        <Route exact path={BOOKING} component={Booking} />
        <Route path={BOOKING_SCREEN} component={SingleBooking} />
        <Route path={CREATE_USER} component={UserCreate} />
        <Route path={ADD_HOTELS} component={AddHotels} />
        <Route exact path={HOTEL_GROUPS} component={HotelsGroups} />
        <Route path={`${HOTEL_GROUPS}/:id`} component={HotelGroupScreen} />
        <Route path={TELEGRAM} component={Telegram} />
        <Route path={WEBINAR_REQUESTS} exact component={WebinarList} />
        <Route path={`${WEBINAR_REQUESTS}/:id`} component={WebinarPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </MainLayout>
  );

  const PublicRoutes = () => (
    <Switch>
      <Redirect exact from="/" to={SIGN_IN} />
      <Route path={SIGN_IN} component={SignIn} />
      <Route path={CREATE_CONFIRM_ROUTE} component={CreateConfirm} />
      <Redirect to={SIGN_IN} />
      <Route path="*" component={NotFound} />
    </Switch>
  );

  return auth && auth.token ? PrivateRoutes() : PublicRoutes();
};


export default connect((store) => store, { onLoginSuccess: loginSuccess })(Routes);
