import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 600,
  },
  searchContainer: {
    padding: 15,
  },
  hover: {
    cursor: 'pointer',
  },
});

const List = ({
  data, columns, searchLabel, action, actionLabel, searchKeys, actions, mainAction, update, customHeaderElement,
}) => {
  const classes = useStyles();
  const [displayData, setDisplayData] = React.useState(data);
  const [page, setPage] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const searhByKeys = (searchData, val) => searchData
    .filter((item) => searchKeys.reduce((acc, key) => {
      if (item[key] && item[key].toLowerCase().includes(val)) return true;
      return acc;
    }, false));

  const onSearchChange = (e) => {
    const val = e.target.value ? e.target.value.toLowerCase() : '';
    const dataToSet = searhByKeys(data, val);
    setSearchValue(val);
    setDisplayData(dataToSet);
  };

  React.useEffect(() => {
    const dataToSet = searhByKeys(data, searchValue);
    setDisplayData(dataToSet);
  }, [update ? 0 : 1]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} className={classes.searchContainer}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={searchLabel}
            onChange={onSearchChange}
          />
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          {customHeaderElement ? customHeaderElement() : null}
        </Grid>
      </Grid>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id || column.code}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {actions && actions.length ? actions.map((item) => (
                <TableCell
                  style={{ minWidth: 100 }}
                  key={item.label}
                >
                  {item.label}
                </TableCell>
              )) : null}
              {action && actionLabel ? (
                <TableCell
                  style={{ minWidth: 100 }}
                >
                  Action
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                hover
                role="button"
                tabIndex={-1}
                key={row.id || row.code}
                onClick={(e) => (mainAction ? mainAction(e, row) : null)}
                className={mainAction ? classes.hover : null}
              >
                {columns.map((column) => {
                  let value = '';
                  if (column.target === 'contacts') {
                    value = row.contacts ? row.contacts[column.id] : '';
                  } else {
                    value = row[column.id] || row[column.otherId];
                  }
                  if (column.type === 'date') {
                    value = value ? moment(value).format('DD MMMM YYYY') : '';
                  }
                  if (column.type === 'date-minutes') {
                    value = value ? moment(value).format('DD MMMM YYYY HH:mm') : '';
                  }
                  if (column.type === 'copy-url') {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value ? (
                          <CopyToClipboard
                            text={value}
                          >
                            <Button variant="contained" color="primary">{value}</Button>
                          </CopyToClipboard>
                        ) : ''}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
                {action && actionLabel ? (
                  <TableCell align="left">
                    <Button variant="contained" onClick={(e) => action(e, row)}>{actionLabel}</Button>
                  </TableCell>
                ) : null}
                {actions && actions.length ? actions.map((item) => (
                  <TableCell align="left" key={`____${item.label}`}>
                    <Button variant="contained" onClick={(e) => item.action(e, row)}>{item.label}</Button>
                  </TableCell>
                )) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={displayData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default List;

List.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  searchLabel: PropTypes.string,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  searchKeys: PropTypes.array,
  actions: PropTypes.array,
  mainAction: PropTypes.func,
  customHeaderElement: PropTypes.func,
  update: PropTypes.bool,
};

List.defaultProps = {
  searchLabel: '',
  action: null,
  mainAction: null,
  actionLabel: '',
  searchKeys: ['name'],
  actions: [],
  update: false,
  customHeaderElement: null,
};
