import React from 'react';
import { connect } from 'react-redux';
import UserCreateForm from '../components/UserCreateForm';
import { createUser } from '../../../store/actions/other';


const UserCreate = ({ onCreateUser }) => (
  <div>
    <UserCreateForm onSubmit={onCreateUser} />
  </div>
);

export default connect(null, { onCreateUser: createUser })(UserCreate);
