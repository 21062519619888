import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  createHotelGroup, getHotelGroup, getHotelsList, updateHotelGroup,
} from '../../../store/actions/hotelGroups';
import HotelGroupForm from '../components/HotelGroupForm';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  flex: {
    display: 'flex',
    marginBottom: 15,
    marginTop: 15,
  },
}));


const HotelGroupScreen = ({
  onGetHotelsList, onGetHotelGroup, onUpdateHotelGroup, match,
}) => {
  const groupId = match.params.id;
  const [hotels, setHotels] = React.useState([]);
  const [groupData, setGroupData] = React.useState(null);

  const getHotels = async () => {
    const data = await onGetHotelsList();
    setHotels(data);
  };

  const getGroupData = async () => {
    const data = await onGetHotelGroup(groupId);
    setGroupData(data);
  };

  React.useEffect(() => {
    getHotels();
    getGroupData();
  }, []);

  const onFormSubmit = async (value) => {
    onUpdateHotelGroup(groupId, value);
  };

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          {groupData ? (
            <HotelGroupForm
              hotels={hotels}
              onSubmit={onFormSubmit}
              initialValues={{
                name: groupData.name,
                ratio: groupData.ratio,
                hotels: groupData.hotels,
              }}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(null, {
  onCreateHotelGroup: createHotelGroup,
  onGetHotelsList: getHotelsList,
  onGetHotelGroup: getHotelGroup,
  onUpdateHotelGroup: updateHotelGroup,
})(HotelGroupScreen);

HotelGroupScreen.propTypes = {
  onGetHotelsList: PropTypes.func.isRequired,
  onGetHotelGroup: PropTypes.func.isRequired,
  onUpdateHotelGroup: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
