import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormInput from '../../../components/FormInput';
import AutocompleteInput from '../../../components/AutocompleteInput';
import CheckBoxRedux from '../../../components/CheckBoxRedux';
import FormSelect from '../../../components/FromSelect';

export const formName = 'SendTelegramMessage';

const ThemesList = [
  { name: 'Introducing a Hotelier', value: 'Introducing a Hotelier' },
  { name: 'Invitation to Event', value: 'Invitation to Event' },
  { name: 'Invitation to Webinar', value: 'Invitation to Webinar' },
  { name: 'Meeting Request', value: 'Meeting Request' },
  { name: 'Booking Offer', value: 'Booking Offer' },
  { name: 'Your Reservation', value: 'Your Reservation' },
  { name: 'Contract', value: 'Contract' },
  { name: 'First Hello from a Hotelier', value: 'First Hello from a Hotelier' },
];


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));

export const getRandom = (maximum = 10000000, minimum = 0) => Math.floor(
  Math.random() * (maximum - minimum + 1),
) + minimum;


const SignInForm = ({ handleSubmit, hotels, agents }) => {
  const classes = useStyles();

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <form className={classes.form} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Title"
                name="title"
                items={ThemesList}
                component={FormSelect}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Hotels"
                name="hotels"
                data={hotels}
                component={AutocompleteInput}
              />
            </Grid>
            <Grid item xs={2} container justify="center">
              <Field
                label="All hotels"
                name="allHotels"
                component={CheckBoxRedux}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Agents"
                name="agents"
                data={agents}
                component={AutocompleteInput}
              />
            </Grid>
            <Grid item xs={2} container justify="center">
              <Field
                label="All agents"
                name="allAgents"
                component={CheckBoxRedux}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              required
              label="Message"
              name="message"
              autoFocus
              component={FormInput}
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button type="submit" variant="contained" color="primary">SEND MESSAGE</Button>
          </Grid>
        </Grid>
      </form>
    </form>
  );
};

export default reduxForm({
  form: formName,
  validate(values) {
    const errors = {};
    if (!values.message || !values.message.length) {
      errors.message = 'Message can not be empty';
    }
    return errors;
  },
})(SignInForm);
