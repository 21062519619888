import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '../../../store/actions/auth';
import HotelGroups from '../../hotel-groups/containers/HotelGroups';
import Agents from '../../agents/containers/Agents';
import Hotels from '../../hotels/containers/Hotels';

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HotelGroups disableButton maxHeight={400} title="Hotel Markups" history={history} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Agents />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Hotels />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(null, { onLogout: logout })(Dashboard);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 340,
  },
}));
