import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  actionButton: {
    backgroundColor: '#efeeee',
    marginBottom: 5,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
}));

const CommentField = ({ onEditBooking, bookingData, setBookingData }) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);
  const [commentValue, setCommentValue] = React.useState(bookingData.user_comment);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setCommentValue(bookingData.user_comment);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    const updatedData = await onEditBooking(bookingData.id, { user_comment: commentValue });
    if (updatedData) {
      setCommentValue(updatedData.user_comment);
      setBookingData(updatedData);
    }
  };

  return (
    <Grid item xs={12} container alignItems="stretch" direction="row" spacing={1}>
      <Grid item xs={12} container alignItems="stretch" direction="column">
        <ListItemText primary="Comment" />
      </Grid>
      <Grid item xs={12} container alignItems="stretch" direction="column">
        {isEditing ? (
          <TextField
            label="Comment"
            multiline
            value={commentValue}
            onChange={(e) => {
              setCommentValue(e.target.value);
            }}
            rows={4}
          />
        ) : (
          <Paper className={classes.paper}>
            <Typography>{bookingData.user_comment}</Typography>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} container>
        {isEditing ? (
          <Grid item xs={12} container spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSaveClick}>Save</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
            </Grid>
          </Grid>
        ) : (
          <Button variant="contained" onClick={handleEditClick}>Edit</Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CommentField;

CommentField.propTypes = {
  setBookingData: PropTypes.func.isRequired,
  onEditBooking: PropTypes.func.isRequired,
  bookingData: PropTypes.object.isRequired,
};
