import {
  SET_AGENTS,
} from '../types';

const initialState = {
  agentsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENTS:
      return {
        ...state,
        agentsList: action.data,
      };
    default:
      return state;
  }
};
