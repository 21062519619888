import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form';
import { getRandom } from '../screens/Telegram/components/SendMessageForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    display: 'flex',
    flex: 1,
  },
  fullWidth: {
    display: 'flex',
    flex: 1,
  },
}));

const AutocompleteInput = ({
  data,
  label = 'Group hotel list',
  input: { onChange, value },
  meta,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        fullWidth
        defaultValue={value}
        classes={{ root: classes.fullWidth }}
        key={getRandom()}
        options={data}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(e, value) => onChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            fullWidth
            placeholder={label}
            meta={meta}
            {...props}
          />
        )}
      />

    </div>
  );
};

export default AutocompleteInput;
