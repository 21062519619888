export const ROOT = '/';
export const SIGN_IN = '/sign-in';
export const DASHBOARD = '/dashboard';
export const TELEGRAM = '/telegram';
export const AGENTS = '/agents';
export const HOTELS = '/hotels';
export const REPORTS = '/reports';
export const HOTEL_GROUPS = '/hotel-markups';
export const CREATE_USER = '/create-user';
export const CREATE_CONFIRM_ROUTE = '/create/:id';
export const BOOKING = '/booking';
export const BOOKING_SCREEN = '/booking/:id';
export const ADD_HOTELS = '/hotels-add';
export const WEBINAR_REQUESTS = '/webinar-requests';
