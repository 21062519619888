import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '../../../components/List';
import { cancelBooking, getBookings } from '../../../store/actions/bookings';
import { BOOKING } from '../../../routes/routes';

const columns = [
  { id: 'Agent.name', label: 'Agent name', minWidth: 170 },
  { id: 'Hotel.name', label: 'Hotel name', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 100 },
  {
    id: 'updatedAt', label: 'Updated At', minWidth: 140, type: 'date-minutes',
  },
  { id: 'selectedRoomName', label: 'Room name', minWidth: 170 },
  {
    id: 'checkin', label: 'Checkin', minWidth: 120, type: 'date',
  },
  {
    id: 'checkout', label: 'Checkout', minWidth: 120, type: 'date',
  },
  { id: 'meal', label: 'Meal type', minWidth: 100 },
  { id: 'price', label: 'Price', minWidth: 100 },
  {
    id: 'initialPrice', label: 'Initial Price', minWidth: 100, otherId: 'price',
  },
  { id: 'markup', label: 'Markup', minWidth: 100 },

];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));


const Booking = ({
  bookingList, onGetBookings, onCancelBooking, history,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    onGetBookings();
  }, []);


  const handleOpenDetails = (e, data) => history.push(`${BOOKING}/${data.id}`, data);
  const handleCancelBooking = async (e, data) => {
    e.stopPropagation();
    setIsLoading(true);
    await onCancelBooking(data.id);
    setIsLoading(false);
  };

  const actions = [
    { label: 'Cancel', action: handleCancelBooking, minWidth: 170 },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          {bookingList && bookingList.length ? (
            <List
              data={bookingList}
              columns={columns}
              searchLabel="Search booking by agent name"
              searchKeys={['Agent.name', 'Hotel.name']}
              actions={actions}
              mainAction={handleOpenDetails}
              update={isLoading}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ bookings: { bookingList } }) => ({ bookingList }),
  {
    onGetBookings: getBookings,
    onCancelBooking: cancelBooking,
  },
)(Booking);

Booking.propTypes = {
  onCancelBooking: PropTypes.func.isRequired,
  bookingList: PropTypes.array.isRequired,
  onGetBookings: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
