import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import CreateConfirmForm from './CreateConfirmForm';
import { SIGN_IN } from '../../routes/routes';
import { getConfirmData, executeConfirmData } from '../../store/actions/auth';

class CreateConfirmScreen extends React.Component {
  state = {
    item: null,
    loading: false,
    error: false,
  };

  componentDidMount() {
    const { onGetConfirmData, match } = this.props;
    this.setState({ loading: true }, async () => {
      const data = await onGetConfirmData(match.params.id);

      if (data) {
        this.setState({
          loading: false,
          item: data,
        });
      } else {
        this.setState({
          loading: false,
          error: true,
        });
      }
    });
  }

  createConfirm = async (values) => {
    const {
      match, history, onExecuteConfirmData,
    } = this.props;
    try {
      await onExecuteConfirmData({
        id: match.params.id,
        password: values.password,
      });
      history.push(SIGN_IN);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { loading, error, item } = this.state;

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={4}>
          <CreateConfirmForm
            enableReinitialize
            initialValues={item && item.data ? item.data : {}}
            disabled={loading || error}
            onSubmit={this.createConfirm}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, {
  onGetConfirmData: getConfirmData,
  onExecuteConfirmData: executeConfirmData,
})(CreateConfirmScreen);
