import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '../../../components/List';
import { getWebinarList } from '../../../store/actions/webinar';
import { BOOKING, WEBINAR_REQUESTS } from '../../../routes/routes';

const columns = [
  { id: 'hotelName', label: 'Hotel', minWidth: 100 },
  { id: 'agents', label: 'Requested', minWidth: 100 },
  { id: 'time', label: 'Prefered date', minWidth: 100 },
  {
    id: 'ZoomMeeting.start_time', label: 'Appointment date', minWidth: 140, type: 'date-minutes',
  },
  {
    id: 'ZoomMeeting.join_url', label: 'Join link', minWidth: 140, type: 'copy-url',
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));


const WebinarList = ({
  webinarsList, onGetWebinarList, history,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    onGetWebinarList();
  }, []);

  const handleOpenDetails = (e, data) => history.push(`${WEBINAR_REQUESTS}/${data.id}`, data);

  console.log(webinarsList);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          {webinarsList && webinarsList.length ? (
            <List
              data={webinarsList}
              columns={columns}
              searchLabel="Search booking by agent name"
              searchKeys={['hotelName']}
              update={isLoading}
              mainAction={handleOpenDetails}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ webinar: { webinarsList } }) => ({ webinarsList }),
  {
    onGetWebinarList: getWebinarList,
  },
)(WebinarList);

WebinarList.propTypes = {
  onGetWebinarList: PropTypes.func.isRequired,
  bookingList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};
