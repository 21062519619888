import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0.6)',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Loader = ({ isLoading }) => {
  const classes = useStyles();
  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
