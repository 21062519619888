import React, { useContext, useRef, useState } from 'react';
import { DatePicker, MuiPickersContext } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useStyles as dayStyles } from '@material-ui/pickers/views/Calendar/Day';
import moment from 'moment';

const DateRangePicker = ({
  classes,
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onClose,
  defaultValue,
  labelFormat,
  minDate,
  maxDate,
  ...props
}) => {
  const [begin, setBegin] = useState(undefined);
  const [end, setEnd] = useState(undefined);
  const [hover, setHover] = useState(undefined);
  const picker = useRef();
  const utils = useContext(MuiPickersContext);

  const min = Math.min(begin || hover, end || hover);
  const max = Math.max(begin || hover, end || hover);

  const baseClasses = dayStyles();

  const sortDates = (dates) => dates.sort((a, b) => {
    if (moment(a).isAfter(moment(b))) return 1;
    return -1;
  });

  const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => React.cloneElement(dayComponent, {
    onClick: (e) => {
      e.stopPropagation();
      if (!begin) setBegin(day);
      else if (!end) {
        setEnd(day);
        if (autoOk) {
          onChange(sortDates([begin, day]));
          if (picker && picker.current) {
            picker.current.close();
          }
        }
      } else {
        setBegin(day);
        setEnd(undefined);
      }
    },
    onMouseEnter: () => setHover(day),
    selected: day >= min && day <= max,
    className: clsx(classes.day, [
      dayComponent.props.hidden && [baseClasses.hidden, classes.hidden],
      dayComponent.props.current && [baseClasses.current, classes.current],
      dayComponent.props.disabled && [baseClasses.dayDisabled, classes.dayDisabled],
      day >= min && day <= max && [baseClasses.daySelected, classes.daySelected],
      utils.isSameDay(day, min) && [baseClasses.beginCap, classes.beginCap],
      utils.isSameDay(day, max) && [baseClasses.endCap, classes.endCap],
    ]),
  });

  const formatDate = (date) => `${moment(date[0]).format(labelFormat)} - ${moment(date[1]).format(labelFormat)}`;

  return (
    <DatePicker
      {...props}
      value={begin}
      renderDay={renderDay}
      onClose={() => {
        onChange(sortDates([begin, end]));
        if (onClose) onClose();
      }}
      onChange={() => {
        onChange(sortDates([begin, end]));
      }}
      onClear={() => {
        setBegin(undefined);
        setEnd(undefined);
        setHover(undefined);
        onChange([]);
      }}
      ref={picker}
      minDate={minDate}
      maxDate={maxDate}
      labelFunc={(date) => (labelFunc
        ? labelFunc(sortDates([begin, end]))
        : date && begin && end
          ? formatDate(sortDates([begin, end]))
          : emptyLabel || '')}
    />
  );
};

export const styles = () => ({
  day: {
    margin: 0,
    width: '40px',
    borderRadius: '0',
    transition: 'none',
    color: '#000',
  },
  dayDisabled: {
    color: '#ccc',
  },
  daySelected: {
    color: '#fff',
  },
  beginCap: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endCap: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
});

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);
