import React from 'react';


const NotFound = () => (
  <div>
    NotFound
  </div>
);

export default NotFound;
