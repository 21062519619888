import { requestHandler } from '../../routes';
import { SET_GROUPS } from '../types';
import { setSnackBar } from './other';

const baseUrl = '/api/hotel-group';

export const createHotelGroup = () => (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `${baseUrl}/${user.adminId}/`;

  return requestHandler.send({
    url,
    method: 'POST',
  });
};

export const getGroupList = (name = '') => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `${baseUrl}/${user.adminId}/search?name=${name}`;

  const data = await requestHandler.send({
    url,
    method: 'GET',
  });

  if (data) {
    dispatch({
      type: SET_GROUPS,
      payload: data,
    });
  }
};


export const getHotelGroup = (groupId) => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `${baseUrl}/${user.adminId}/group/${groupId}`;

  return requestHandler.send({
    url,
    method: 'GET',
  });
};

export const getHotelsList = () => async (dispatch, getState) => {
  const { auth: { user } } = getState();

  const url = `${baseUrl}/${user.adminId}/hotels`;

  return requestHandler.send({
    url,
    method: 'GET',
  });
};

export const deleteHotelGroup = (groupId) => async (dispatch, getState) => {
  const { auth: { user } } = getState();

  const url = `${baseUrl}/${user.adminId}/group/${groupId}`;

  const data = await requestHandler.send({
    url,
    method: 'delete',
  });

  console.log(data);

  if (data) {
    dispatch({
      type: SET_GROUPS,
      payload: data,
    });
  }
};

export const updateHotelGroup = (groupId, groupData) => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `${baseUrl}/${user.adminId}/group/${groupId}`;

  const data = {
    ...groupData,
    hotels: groupData.hotels.map((hotel) => hotel.id),
  };
  try {
    const result = await requestHandler.send({
      url,
      method: 'PUT',
      data,
    });
    if (result && result.error) {
      let message = 'Error';
      if (result.hasAnotherGroup) {
        const hotelNames = result.hasAnotherGroup.map((hotel) => `Group: ${hotel.Group.name} Hotel: ${hotel.Hotel.name}  \n`);
        message = `Error! Some hotels already has group. Please check groups: \n ${hotelNames.join(' ')}`;
      }
      dispatch(setSnackBar({ open: true, message, severity: 'error' }));
    } else {
      dispatch(setSnackBar({ open: true, message: 'Successfully saved!', severity: 'success' }));
      dispatch(getHotelsList());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
