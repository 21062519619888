import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { SIGN_IN } from '../../routes/routes';
import FormInput from '../../components/FormInput';

class CreateConfirmForm extends React.Component {
  render() {
    const {
      invalid,
      handleSubmit,
      submitting,
      disabled,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="sing-up_form">
        <Card>
          <CardContent>
            <Typography variant="headline">Confirm creation</Typography>

            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  margin="dense"
                  name="first_name"
                  label="First name"
                  disabled
                  component={FormInput}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  margin="dense"
                  name="last_name"
                  disabled
                  label="Last name"
                  component={FormInput}
                />
              </Grid>
            </Grid>

            <div>
              <Field
                margin="dense"
                fullWidth
                name="name"
                label="Admin"
                disabled
                defaultValue=""
                component={FormInput}
              />
            </div>

            <div>
              <Field
                margin="dense"
                fullWidth
                name="email"
                label="Email"
                disabled
                component={FormInput}
              />
            </div>

            <div>
              <Field
                margin="dense"
                fullWidth
                name="password"
                label="Password"
                disabled={submitting || disabled}
                component={FormInput}
              />
            </div>

          </CardContent>

          <CardActions className="sing-up_form__buttons">
            <Grid item xs>
              <Button onClick={() => this.navigate(SIGN_IN)}>
                sign in
              </Button>
            </Grid>
            <Button
              type="submit"
              disabled={invalid || submitting || disabled}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </CardActions>

        </Card>
      </form>
    );
  }
}


export default reduxForm({
  form: 'create-confirm',
  validate(values) {
    const errors = {};
    if (!values.password || values.password.length < 6) {
      errors.password = 'passwordError';
    }
    return errors;
  },
})(CreateConfirmForm);
