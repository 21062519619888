import React, { useState } from 'react';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createHotelGroup, deleteHotelGroup, getGroupList } from '../../../store/actions/hotelGroups';
import StickyHeadTable from '../components/List';
import { HOTEL_GROUPS } from '../../../routes/routes';
import { setGlobalRate } from '../../../store/actions/other';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
    maxHeight: (props) => props.maxHeight,
  },
}));


const HotelGroups = ({
  onCreateHotelGroup,
  onGetGroupList,
  onDeleteHotelGroup,
  history,
  disableButton,
  maxHeight,
  title,
  groups,
  user,
  onSetGlobalRate,
}) => {
  const classes = useStyles({ maxHeight });
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { markup } = user;
  const [rate, setRate] = useState(markup);
  React.useEffect(() => {
    onGetGroupList();
  }, []);

  const addHotelGroup = async () => {
    const data = await onCreateHotelGroup();
    history.push(`${HOTEL_GROUPS}/${data.id}`);
  };

  const navigateTo = async (route) => history.push(route);

  const onDeleteGroup = async (groupId) => {
    await onDeleteHotelGroup(groupId);
  };

  const searchGroupByHotelName = debounce((value) => {
    onGetGroupList(value);
  }, 600);

  const onSearchHotel = (e) => {
    const value = e.target.value ? e.target.value.toLowerCase().trim() : '';
    searchGroupByHotelName(value);
  };

  const handleSetRate = (e) => {
    const val = e.target.value;
    if (val >= 0 && val <= 10) {
      setRate(parseFloat(e.target.value).toFixed(1));
    }
  };

  const handleUpdateRate = () => onSetGlobalRate(rate);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <TextField
                name="Markup"
                label="Global markup, %"
                fullWidth
                type="number"
                value={parseFloat(rate).toFixed(1)}
                onChange={handleSetRate}
              />
            </Grid>
            <Grid item xs={2}>
              <Button color="secondary" variant="contained" onClick={handleUpdateRate}>Save</Button>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Typography>{title}</Typography>
          <TextField
            name="Search"
            label="Search hotel group by hotel name"
            onChange={onSearchHotel}
          />
          <StickyHeadTable
            data={groups.map((group) => ({ ...group, hotels: group.hotels.join(', ') }))}
            navigateTo={navigateTo}
            onDeleteGroup={onDeleteGroup}
          />
        </Paper>
      </Grid>
      {!disableButton ? (
        <IconButton color="inherit" onClick={addHotelGroup}>
          <AddCircleIcon fontSize="large" />
        </IconButton>
      ) : null}
    </Grid>
  );
};

export default connect(({ hotelGroups: { groups }, auth: { user } }) => ({ groups, user }), {
  onCreateHotelGroup: createHotelGroup,
  onGetGroupList: getGroupList,
  onDeleteHotelGroup: deleteHotelGroup,
  onSetGlobalRate: setGlobalRate,
})(HotelGroups);

HotelGroups.propTypes = {
  onCreateHotelGroup: PropTypes.func.isRequired,
  onGetGroupList: PropTypes.func.isRequired,
  onDeleteHotelGroup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  disableButton: PropTypes.bool,
  maxHeight: PropTypes.number,
  title: PropTypes.string,
  groups: PropTypes.array,
};

HotelGroups.defaultProps = {
  disableButton: false,
  maxHeight: 800,
  title: '',
  groups: [],
};
