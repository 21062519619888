import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getAgents } from '../../../store/actions/agents';
import List from '../../../components/List';
import { resetUserPassword } from '../../../store/actions/other';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'address', label: 'Address', minWidth: 170 },
  { id: 'foundation', label: 'Foundation', minWidth: 170 },
  {
    id: 'username', label: 'Username', minWidth: 170,
  },
];

const Agents = ({ agentsList, onGetAgents, onResetUserPassword }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  React.useState(() => {
    onGetAgents();
  }, []);

  const onResetPassword = (e, agent) => {
    if (agent['Users.User.id']) {
      onResetUserPassword(agent['Users.User.id'], agent.username);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          {agentsList && agentsList.length ? (
            <List
              data={agentsList}
              columns={columns}
              searchLabel="Search agent by name"
              action={onResetPassword}
              actionLabel="Reset password"
              searchKeys={['Agent.name', 'username']}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ agents: { agentsList } }) => ({ agentsList }),
  {
    onGetAgents: getAgents,
    onResetUserPassword: resetUserPassword,
  },
)(Agents);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));

Agents.propTypes = {
  onGetAgents: PropTypes.func.isRequired,
  onResetUserPassword: PropTypes.func.isRequired,
  agentsList: PropTypes.array.isRequired,
};
