import Cookies from 'js-cookie';
import { requestHandler } from '../../routes';
import {
  COOKIE_NAME, LOGOUT, USER_LOGIN_SUCCESS, USER_SET_DATA,
} from '../types';
import { getPayCard } from './other';

export const CONFIRM = '/boot';

export const loginSuccess = (token) => (dispatch) => {
  requestHandler.updateProps({ token });
  dispatch({
    type: USER_LOGIN_SUCCESS,
    token,
  });
};

export const setUserData = (data) => ({
  type: USER_SET_DATA,
  data,
});

export const logout = () => (dispatch) => {
  Cookies.remove(COOKIE_NAME);
  dispatch({ type: LOGOUT });
};

export const logIn = (payload) => async (dispatch) => {
  const data = await requestHandler.send({
    url: 'auth/local',
    method: 'POST',
    data: payload,
  });

  if (data && data.token) {
    Cookies.set(COOKIE_NAME, data.token, { expires: 7 });
    dispatch(loginSuccess(data.token));
  }

  return data;
};

export const getUserId = () => async (dispatch) => {
  const data = await requestHandler.send({
    url: 'api/user/admin',
    method: 'GET',
  });

  dispatch(setUserData(data));
  return data;
};

export const getAdminUser = () => async (dispatch) => {
  dispatch(getUserId());
  dispatch(getPayCard());
};


export const getConfirmData = (id) => () => {
  const url = `${CONFIRM}/${id}`;
  return requestHandler.send({
    url,
    method: 'GET',
  });
};

export const executeConfirmData = ({ id, password }) => () => {
  const url = `${CONFIRM}/${id}/execute`;

  return requestHandler.send({
    url,
    method: 'POST',
    data: {
      password,
    },
  });
};
