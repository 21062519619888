import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {
  createZoomMeeting, deleteZoomMeeting, getWebinarPage, sendZoomInvites, updateWebinar, updateZoomMeeting,
} from '../../../store/actions/webinar';
import WebinarForm from '../components/WebinarForm';
import { getAgents } from '../../../store/actions/agents';
import Loader from '../../../components/Loader';
import { setSnackBar } from '../../../store/actions/other';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: 15,
  },
  fixedHeight: {
    minHeight: 240,
  },
}));


const WebinarPage = ({
  onGetWebinarPage,
  onGetAgents,
  agentsList,
  match: { params },
  onUpdateWebinar,
  onCreateZoomMeeting,
  onUpdateZoomMeeting,
  onSetSnackBar,
  onDeleteZoomMeeting,
  onSendZoomInvites,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isLoading, setIsLoading] = React.useState(false);
  const [webinarData, setWebinarData] = React.useState(null);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const getWebinar = async () => {
    setIsLoading(true);
    const data = await onGetWebinarPage(params.id);
    setWebinarData(data);
    await onGetAgents();
    setIsLoading(false);
  };

  const onSaveWebinar = async (value) => {
    setIsLoading(true);
    const diffsKeys = Object.keys(value).filter((k) => webinarData[k] !== value[k]);
    const diffs = diffsKeys.reduce((acc, item) => {
      acc[item] = value[item];
      return acc;
    }, {});
    await onUpdateWebinar(params.id, diffs);
    await getWebinar();
    setIsLoading(false);
  };

  const handleCreateZoom = async () => {
    setIsLoading(true);
    await onCreateZoomMeeting(params.id);
    await getWebinar();
    setIsLoading(false);
  };

  const handleUpdateeZoom = async () => {
    setIsLoading(true);
    await onUpdateZoomMeeting(params.id);
    await getWebinar();
    setIsLoading(false);
  };

  const handleAskDeleteZoom = () => setIsDelete(true);
  const onDismiss = () => setIsDelete(false);

  const handleDeleteZoom = async () => {
    setIsDelete(false);
    setIsLoading(true);
    await onDeleteZoomMeeting(params.id);
    await getWebinar();
    setIsLoading(false);
  };

  const handleSendInvites = () => onSendZoomInvites(params.id);

  React.useEffect(() => {
    getWebinar();
  }, []);

  if (!webinarData || !agentsList.length) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <CircularProgress />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const handleCopyUrl = () => {
    onSetSnackBar({ open: true, message: 'Copied' });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Grid container item xs={12}>
            <WebinarForm
              onSubmit={onSaveWebinar}
              agents={agentsList.map(({ name, id }) => ({ name, id }))}
              initialValues={{
                ...webinarData,
              }}
              setIsDirty={setIsDirty}
            />
          </Grid>
        </Paper>
        <Paper className={fixedHeightPaper}>
          <Grid container item xs={12} style={{ marginBottom: 15 }}>
            <Grid item xs={12}>
              <Typography>Zoom data</Typography>
            </Grid>
            {webinarData['ZoomMeeting.id'] ? (
              <>
                <Grid item xs={12}>
                  <Typography>
                    <b>Join url: </b>
                    <a href={webinarData['ZoomMeeting.join_url']} target="_blank" rel="noopener noreferrer">
                      {webinarData['ZoomMeeting.join_url']}
                    </a>
                    <span>   </span>
                    <CopyToClipboard
                      text={webinarData['ZoomMeeting.join_url']}
                      onCopy={handleCopyUrl}
                    >
                      <Button variant="contained" color="primary">COPY URL</Button>
                    </CopyToClipboard>
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid container spacing={3} justify="space-between">
            <Grid container item xs={4}>
              {webinarData['ZoomMeeting.id'] ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isDirty}
                  onClick={handleUpdateeZoom}
                >
                  Update zoom meeting
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!!webinarData['ZoomMeeting.id'] || isDirty}
                  onClick={handleCreateZoom}
                >
                  Create zoom meeting
                </Button>
              )}
            </Grid>
            {webinarData['ZoomMeeting.id'] ? (
              <Grid container item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="default"
                  onClick={handleAskDeleteZoom}
                >
                  Delete zoom meeting
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {webinarData['ZoomMeeting.id'] ? (
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid container item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={isDirty}
                  onClick={handleSendInvites}
                >
                  Send invites
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Grid>
      <Dialog
        open={isDelete}
        onClose={onDismiss}
      >
        <DialogTitle id="alert-dialog-title">Do you want to delete zoom meeting?</DialogTitle>
        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            NO
          </Button>
          <Button onClick={handleDeleteZoom} color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Loader isLoading={isLoading} />
    </Grid>
  );
};

export default connect(
  ({ agents: { agentsList } }) => ({ agentsList }),
  {
    onGetWebinarPage: getWebinarPage,
    onGetAgents: getAgents,
    onUpdateWebinar: updateWebinar,
    onSetSnackBar: setSnackBar,
    onCreateZoomMeeting: createZoomMeeting,
    onUpdateZoomMeeting: updateZoomMeeting,
    onDeleteZoomMeeting: deleteZoomMeeting,
    onSendZoomInvites: sendZoomInvites,
  },
)(WebinarPage);

WebinarPage.propTypes = {
  onGetWebinarPage: PropTypes.func.isRequired,
  onUpdateWebinar: PropTypes.func.isRequired,
  onGetAgents: PropTypes.func.isRequired,
  onCreateZoomMeeting: PropTypes.func.isRequired,
  onUpdateZoomMeeting: PropTypes.func.isRequired,
  onDeleteZoomMeeting: PropTypes.func.isRequired,
  onSetSnackBar: PropTypes.func.isRequired,
  onSendZoomInvites: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  agentsList: PropTypes.array.isRequired,
};
