import { SET_HOTELS } from '../types';
import { requestHandler } from '../../routes';

export const setHotels = (data) => ({
  type: SET_HOTELS,
  data,
});

export const getHotels = () => async (dispatch) => {
  const data = await requestHandler.send({
    url: '/api/admin/hotels-list',
    method: 'GET',
  });

  dispatch(setHotels(data));
  return data;
};
