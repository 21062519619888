import React, {  useRef } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';

const FormDateTimePicker = ({
  input,
  ...props
}) => {
  const value = moment(input.value).toDate();
  const picker = useRef();
  return (
    <DateTimePicker
      {...props}
      ref={picker}
      onChange={(value) => {
        input.onChange(moment(value).format());
      }}
      ampm={false}
      value={value}
    />
  );
};

export const styles = () => ({
  day: {
    margin: 0,
    width: '40px',
    borderRadius: '0',
    transition: 'none',
    color: '#000',
  },
  dayDisabled: {
    color: '#ccc',
  },
  daySelected: {
    color: '#fff',
  },
  beginCap: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endCap: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
});

export default withStyles(styles, { name: 'DateRangePicker' })(FormDateTimePicker);
