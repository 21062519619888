import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';


const FormSelect = ({
  label,
  input,
  meta: { touched, invalid, error },
  items,
  ...custom
}) => (
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  >
    {items.map((item) => (
      <MenuItem value={item.value}>{item.name}</MenuItem>
    ))}
  </Select>
);

export default FormSelect;


FormSelect.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

FormSelect.defaultProps = {
  label: '',
};
