import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { HOTEL_GROUPS } from '../../../routes/routes';

const columns = [
  { id: 'index', label: '#', minWidth: 30 },
  { id: 'name', label: 'Name', minWidth: 200 },
  { id: 'hotels', label: 'Hotels', minWidth: 200 },
  { id: 'ratio', label: 'Rate', minWidth: 100 },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
  hovered: {
    cursor: 'pointer',
  },
});

const HotelGroupList = ({ data, navigateTo, onDeleteGroup }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [groupToDelete, setGroupToDelete] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onDelete = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setGroupToDelete(item.id);
  };

  const onDismiss = () => setGroupToDelete(null);

  const deleteGroup = async () => {
    await onDeleteGroup(groupToDelete);
    onDismiss();
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                style={{ minWidth: 100 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                onClick={() => navigateTo(`${HOTEL_GROUPS}/${row.id}`)}
                className={classes.hovered}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
                <TableCell align="left">
                  <Button onClick={(e) => onDelete(e, row)}>DELETE</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Dialog
        open={!!groupToDelete}
        onClose={onDismiss}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Do you want to delete group?</DialogTitle>
        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            NO
          </Button>
          <Button onClick={deleteGroup} color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default HotelGroupList;
