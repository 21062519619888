import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import hotelGroups from './hotelGroups';
import other from './other';
import hotels from './hotels';
import agents from './agents';
import bookings from './bookings';
import webinar from './webinar';
import telegram from './telegram';

export default {
  form: formReducer,
  auth,
  hotels,
  agents,
  bookings,
  webinar,
  telegram,
  other,
  hotelGroups,
};
