import axios from 'axios';

let baseURL;

console.log(process.env.REACT_APP_ENV);
switch (process.env.REACT_APP_ENV) {
  case 'local':
    baseURL = 'http://localhost:8082';
    break;
  case 'development':
    baseURL = 'https://app-dev.tg-nv.com';
    break;
  case 'test':
    baseURL = 'https://app-test.tg-nv.com';
    break;
  case 'production':
    baseURL = 'https://app.novelvoyage.com';
    break;
  default: baseURL = 'http://localhost:8082';
}
console.log(baseURL);

class RestService {
  constructor() {
    this.agent = axios.create({ baseURL });
    this.agent.defaults.headers.common = {};
  }

  updateProps = (props) => {
    this.props = { ...this.props, ...props };
    if (props.token) {
      this.agent.defaults.headers.common.Authorization = props.token;
    }
  };

  send = async (data) => {
    try {
      const result = await this.agent.request(data);
      console.log(result);

      return result.data.data || result.data;
    } catch (e) {
      return null;
    }
  };
}

export default RestService;
