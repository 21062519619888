import queryString from 'query-string';
import moment from 'moment';
import { reset } from 'redux-form';
import { requestHandler } from '../../routes';

import {
  REMOVE_SNACKBAR, SET_PAY_BY_CARD, SET_SNACKBAR,
} from '../types';
import {formName} from "../../screens/Telegram/components/SendMessageForm";
import { getAdminUser } from './auth';

export const format = 'YYYY-MM-DD';

export const getNewsletterStats = (initialDates) => async () => {
  const [startDate, endDate] = initialDates.map((date) => moment(date).format(format));

  const dates = queryString.stringify({ startDate, endDate });

  const url = `/api/newsletter-stat/all-stat?${dates}`;

  return requestHandler.send({
    url,
    method: 'GET',
  });
};


export const setSnackBar = (data) => ({
  type: SET_SNACKBAR,
  payload: data,
});

export const removeSnackBar = () => ({
  type: REMOVE_SNACKBAR,
});


export const createUser = (data) => async (dispatch, getState) => {
  console.log(data);
  const { auth: { user } } = getState();
  const url = `api/admin/${user.adminId}/create-${data.type}`;

  const res = await requestHandler.send({
    url,
    method: 'POST',
    data,
  });
  if (res) {
    dispatch(setSnackBar({ open: true, message: `You successfully created and ${data.type}` }));
    dispatch(reset('CreateUserForm'));
  }
};

export const addRatehawkHotels = (data) => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `api/admin/${user.adminId}/add-ratehawk-hotels`;
  dispatch(setSnackBar({ open: true, message: 'Hotels started loading...' }));

  const res = await requestHandler.send({
    url,
    method: 'POST',
    data,
  });
  console.log(res);
  if (res) {
    dispatch(setSnackBar({ open: true, message: 'Hotels was successfully uploaded' }));
  } else {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong. Please try again later', severity: 'error' }));
  }
};

export const updateRatehawkHotels = (data) => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `api/admin/${user.adminId}/add-ratehawk-data-hotels`;
  dispatch(setSnackBar({ open: true, message: 'Hotels started updating...' }));

  const res = await requestHandler.send({
    url,
    method: 'POST',
    data,
  });
  if (res) {
    dispatch(setSnackBar({ open: true, message: 'Hotels was successfully uploaded' }));
  } else {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong. Please try again later', severity: 'error' }));
  }
};

export const checkUploadedHotels = () => async (dispatch) => {
  const url = 'api/booking-parse/combine-hotels';
  dispatch(setSnackBar({ open: true, message: 'Hotels started checking...' }));

  const res = await requestHandler.send({
    url,
    method: 'GET',
  });
  if (res) {
    const { updatedHotels, duration } = res;
    dispatch(setSnackBar({ open: true, message: `Updated hotels: ${updatedHotels.length} Duration: ${duration}` }));
  } else {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong. Please try again later', severity: 'error' }));
  }
};

export const resetUserPassword = (userId, username = '') => async (dispatch, getState) => {
  const { auth: { user } } = getState();
  const url = `api/admin/${user.adminId}/reset-password/${userId}`;

  console.log(url);
  const res = await requestHandler.send({
    url,
    method: 'GET',
  });
  console.log(res);
  if (res) {
    dispatch(setSnackBar({ open: true, message: `Password successfully reset for user ${username}` }));
    dispatch(reset('CreateUserForm'));
  }
};

export const getHotelContactReports = ([from, to]) => async () => {
  const dates = queryString.stringify({ from, to });

  const res = await requestHandler.send({
    url: `/reports/hotel-contacts-request?${dates}`,
    method: 'GET',
    responseType: 'blob',
  });

  if (res) {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
  }
};

export const sendTelegramMessage = (data) => async (dispatch) => {
  const res = await requestHandler.send({
    url: 'api/admin/sendTelegramMessage',
    method: 'POST',
    data,
  });
  if (res) {
    dispatch(setSnackBar({ open: true, message: 'Messages sent to users' }));
    dispatch(reset(formName));
  }
};

const onSetCardPay = (value) => (dispatch) => {
  dispatch({
    type: SET_PAY_BY_CARD,
    payload: value || false,
  });
};

export const setPayByCard = (data) => async (dispatch) => {
  const url = 'api/admin/pay-card';
  dispatch(setSnackBar({ open: true, message: 'Hotels started updating...' }));

  const res = await requestHandler.send({
    url,
    method: 'POST',
    data,
  });
  if (res) {
    dispatch(onSetCardPay(data.value));
    if (data.value) {
      dispatch(setSnackBar({ open: true, message: '' }));
    } else {
      dispatch(setSnackBar({ open: true, message: '' }));
    }
  } else {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong. Please try again later', severity: 'error' }));
  }
};

export const getPayCard = () => async (dispatch) => {
  const url = 'api/admin/pay-card';

  const res = await requestHandler.send({
    url,
    method: 'GET',
  });

  dispatch(onSetCardPay(res.value));
};


export const setGlobalRate = (value) => async (dispatch) => {
  const url = 'api/admin/update-markup';
  const res = await requestHandler.send({
    url,
    method: 'POST',
    data: { value },
  });
  if (res) {
    dispatch(getAdminUser());
  } else {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong. Please try again later', severity: 'error' }));
  }
};
