import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FromSelect';

const UserCreateForm = ({ handleSubmit }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <form noValidate>
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Name"
        name="name"
        autoFocus
        component={FormInput}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="First name"
        name="first_name"
        component={FormInput}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Last name"
        name="last_name"
        component={FormInput}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Type"
        name="type"
        items={[
          {
            name: 'Agent',
            value: 'agent',
          },
          {
            name: 'Hotel',
            value: 'hotel',
          },
        ]}
        component={FormSelect}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="username"
        autoComplete="off"
        component={FormInput}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="text"
        id="password"
        autoComplete="off"
        component={FormInput}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
      >
        Create
      </Button>
    </form>
  </form>
);

export default reduxForm({
  form: 'CreateUserForm',
  validate(values) {
    const errors = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }
    if (!values.first_name) {
      errors.first_name = 'First name is required';
    }
    if (!values.last_name) {
      errors.last_name = 'Last name is required';
    }
    if (!values.type) {
      errors.type = 'Type is required';
    }
    if (!values.username) {
      errors.username = 'Email is required';
    }
    if (!values.password || values.password.length < 6) {
      errors.password = 'Password should be not less then 6 symbols';
    }
    return errors;
  },
})(UserCreateForm);
