import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form';
import { getRandom } from '../screens/Telegram/components/SendMessageForm';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const CheckBoxRedux = ({
  label,
  input: { onChange, value },
  meta,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={value}
          onChange={onChange}
          name="checkedB"
          color="primary"
          {...meta}
          {...props}
        />
              )}
      label={label}
    />
  );
};

export default CheckBoxRedux;
