import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setup as storeSetup } from './store/index';
import Routes from './routes';
import { ROOT } from './routes/routes';

const Root = () => {
  const store = storeSetup();

  return (
    <Provider store={store}>
      <Router>
        <Route path={ROOT} component={Routes} />
      </Router>
    </Provider>
  );
};

export default Root;
