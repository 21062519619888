import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '../../../components/List';
import { getTelegramList } from '../../../store/actions/telegram';

const columns = [
  { id: 'id', label: 'id', minWidth: 100 },
  { id: 'title', label: 'title', minWidth: 100 },
  { id: 'description', label: 'description', minWidth: 100 },
  { id: 'createdAt', label: 'Created', minWidth: 140, type: 'date-minutes' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));


const TelegramRequestsList = ({
  telegramRequestsList, onGetTelegramList, history,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    onGetTelegramList();
  }, []);

  console.log(telegramRequestsList);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          {telegramRequestsList && telegramRequestsList.length ? (
            <List
              data={telegramRequestsList}
              columns={columns}
              searchLabel="Search booking by agent name"
              searchKeys={['id']}
              update={isLoading}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ telegram: { telegramRequestsList } }) => ({ telegramRequestsList }),
  {
    onGetTelegramList: getTelegramList,
  },
)(TelegramRequestsList);

TelegramRequestsList.propTypes = {
  onGetTelegramList: PropTypes.func.isRequired,
  telegramRequestsList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};
