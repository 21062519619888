import {
  USER_LOGIN_SUCCESS,
  LOGOUT,
  USER_SET_DATA,
} from '../types';

const initialState = {
  token: null,
  user: null,
  userId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case USER_SET_DATA:
      return {
        ...state,
        user: action.data,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
