export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_SET_DATA = 'USER_SET_DATA';
export const SET_GROUPS = 'SET_GROUPS';
export const LOGOUT = 'LOGOUT';

export const COOKIE_NAME = 'tgnv_admin';


export const SET_SNACKBAR = 'SET_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const SET_AGENTS = 'SET_AGENTS';
export const SET_HOTELS = 'SET_HOTELS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_PAY_BY_CARD = 'SET_PAY_BY_CARD';

export const SET_TELEGRAM_LIST = 'SET_TELEGRAM_LIST';

export const SET_WEBINAR_LIST = 'SET_WEBINAR_LIST';
