import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getHotels } from '../../../store/actions/hotels';
import List from '../../../components/List';
import { resetUserPassword, setPayByCard } from '../../../store/actions/other';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'address', label: 'Address', minWidth: 170 },
  { id: 'username', label: 'Username', minWidth: 170 },
  { id: 'brand', label: 'Brand', minWidth: 170 },
  { id: 'stars', label: 'Stars', minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));


const Hotels = ({
  hotelsList, onGetHotels, onResetUserPassword, cardPay, onSetPayByCard,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  React.useEffect(() => {
    onGetHotels();
  }, []);

  const onResetPassword = (e, hotel) => {
    if (hotel['HotelUsers.User.id']) {
      onResetUserPassword(hotel['HotelUsers.User.id'], hotel.username);
    }
  };

  const handleChangePayByCard = (e, value) => {
    onSetPayByCard({ value: !value });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>

          {hotelsList && hotelsList.length ? (
            <List
              data={hotelsList}
              columns={columns}
              searchLabel="Search hotel by name"
              action={onResetPassword}
              actionLabel="Reset password"
              searchKeys={['name', 'username', 'brand']}
              customHeaderElement={() => (
                <FormControlLabel
                  control={(
                    <Switch
                      checked={!cardPay}
                      onChange={handleChangePayByCard}
                      name="checkedB"
                      color="primary"
                    />
                      )}
                  label="Card pay"
                />
              )}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  ({ hotels: { hotelsList }, other: { cardPay } }) => ({ hotelsList, cardPay }),
  {
    onGetHotels: getHotels,
    onResetUserPassword: resetUserPassword,
    onSetPayByCard: setPayByCard,
  },
)(Hotels);

Hotels.propTypes = {
  onGetHotels: PropTypes.func.isRequired,
  onResetUserPassword: PropTypes.func.isRequired,
  hotelsList: PropTypes.array.isRequired,
};
