import { SET_AGENTS } from '../types';
import { requestHandler } from '../../routes';


export const setAgents = (data) => ({
  type: SET_AGENTS,
  data,
});

export const getAgents = () => async (dispatch) => {
  const data = await requestHandler.send({
    url: '/api/admin/agents-list',
    method: 'GET',
  });

  dispatch(setAgents(data));
  return data;
};
