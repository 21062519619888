import TextField from '@material-ui/core/TextField';
import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export default FormInput;

FormInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

FormInput.defaultProps = {
  label: '',
};
