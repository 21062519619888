import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormInput from '../../../components/FormInput';

const SignInForm = ({ handleSubmit, classes }) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <form className={classes.form} noValidate>
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="username"
        autoComplete="email"
        autoFocus
        component={FormInput}
      />
      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        component={FormInput}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Sign In
      </Button>
    </form>
  </form>
);

export default reduxForm({
  form: 'SignIn',
  validate(values) {
    const errors = {};
    if (!values.username) {
      errors.username = 'loginError';
    }
    if (!values.password || values.password.length < 6) {
      errors.password = 'passwordError';
    }
    return errors;
  },
})(SignInForm);
