import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import defaultReducers from './reducers';

let middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
} else {
  middleware = [...middleware];
}

export const setup = (reducers = {}) => {
  const combinedReducers = combineReducers(({ ...defaultReducers, ...reducers }));
  return createStore(
    combinedReducers,
    applyMiddleware(...middleware),
  );
};
