import {
  REMOVE_SNACKBAR,
  SET_SNACKBAR,
  SET_PAY_BY_CARD,
} from '../types';

const initialState = {
  snackbar: {
    open: false,
    severity: 'success',
    message: '',
  },
  cardPay: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: { ...state.snackbar, ...action.payload },
      };
    case SET_PAY_BY_CARD:
      return {
        ...state,
        cardPay: action.payload,
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        snackbar: initialState.snackbar,
      };
    default:
      return state;
  }
};
