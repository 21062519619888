import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { List as MaterialList } from '@material-ui/core';
import { BOOKING } from '../../../routes/routes';
import {
  cancelBooking, editBooking, getBookingData,
} from '../../../store/actions/bookings';
import CommentField from '../components/CoomentField';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
}));

const format = 'DD MMMM YYYY';


const SingleBooking = ({
  match: { params }, history, onGetBookingData, onCancelBooking, onEditBooking,
}) => {
  const classes = useStyles();
  const [bookingData, setBookingData] = React.useState(null);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleGetBookingData = async () => {
    if (params && params.id) {
      const data = await onGetBookingData(params.id);
      setBookingData(data);
    }
  };

  React.useEffect(() => {
    handleGetBookingData();
  }, []);

  const handleCancelBooking = async () => {
    await onCancelBooking(bookingData.id);
    history.replace(BOOKING);
  };

  if (!bookingData) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <CircularProgress />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const imagesList = bookingData.HotelRoom ? bookingData.HotelRoom.images.map((image) => ({
    ...image,
    src: image.replace('{size}', '1024x768'),
  })) : [];

  const markUp = bookingData.Hotel.HotelGroup
  && bookingData.Hotel.HotelGroup.Group
    ? bookingData.Hotel.HotelGroup.Group.ratio : 0;

  const cancellation = bookingData.cancellationInfo
  && bookingData.cancellationInfo.free_cancellation_before
    ? moment(bookingData.cancellationInfo.free_cancellation_before).format(format) : 'no free cancellation';

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Paper className={fixedHeightPaper}>
          <Typography variant="h5">Info</Typography>
          <Grid container spacing={1}>
            <MaterialList>
              <ListItem>
                <ListItemText primary="Agent" secondary={bookingData.Agent.name} />
                <Divider />
              </ListItem>

              <ListItem>
                <ListItemText primary="Hotel" secondary={bookingData.Hotel.name} />
                <Divider />
              </ListItem>

              <ListItem>
                <ListItemText primary="Room" secondary={bookingData.roomName} />
                <Divider />
              </ListItem>
              <ListItem>
                <ListItemText primary="Room name" secondary={bookingData.selectedRoomName} />
                <Divider />
              </ListItem>
              <ListItem>
                <ListItemText primary="Meal type" secondary={bookingData.meal} />
                <Divider />
              </ListItem>
              <ListItem>
                <ListItemText primary="Rooms: " />
                <MaterialList component="ol">
                  {bookingData.bookingGuests.map((guest, i) => (
                    <ListItem component="li">
                      <ListItemText primary={`${i + 1} room`} secondary={`adults: ${guest.adults} ${guest.children ? `, children ${guest.children}` : ''}`} />
                    </ListItem>
                  ))}
                </MaterialList>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Dates"
                  secondary={`${moment(bookingData.checkin).format(format)} - 
                      ${moment(bookingData.checkout).format(format)} 
                      (${moment(bookingData.checkout).diff(moment(bookingData.checkin), 'days')} nights)`}
                />
                <Divider />
              </ListItem>

              <ListItem>
                <ListItemText primary="Initial price" secondary={`€ ${bookingData.initialPrice || bookingData.price}`} />
                <ListItemText primary="Price with markup" secondary={`€ ${bookingData.price}`} />
                <Divider />
              </ListItem>
              <ListItem>
                <ListItemText primary="Actual markup" secondary={bookingData.markup} />
                <ListItemText primary="Current hotel markup" secondary={`${markUp}%`} />
                <Divider />
              </ListItem>

              <ListItem>
                <ListItemText primary="Free cancellation until" secondary={`${cancellation}`} />
                <Divider />
              </ListItem>
            </MaterialList>

            <Grid item xs={12} container alignItems="stretch" direction="column">
              <Divider />
            </Grid>
            <CommentField
              bookingData={bookingData}
              onEditBooking={onEditBooking}
              setBookingData={setBookingData}
            />
            <Grid item xs={12} container alignItems="stretch" direction="column">
              {imagesList && imagesList.length ? (
                <Grid item xs={12} container>
                  <Grid>
                    <Typography>
                      Images
                    </Typography>
                  </Grid>
                  <Grid>
                    <GridList cellHeight={160} cols={3}>
                      {imagesList.map((img) => (
                        <GridListTile key={img} cols={1}>
                          <img src={img} alt={img.category} />
                        </GridListTile>
                      ))}
                    </GridList>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={fixedHeightPaper}>
          <Typography variant="h5">Actions</Typography>
          <Grid container spacing={1}>
            <MaterialList component="nav">
              <ListItem component="button" button classes={{ root: classes.actionButton }} onClick={handleCancelBooking}>
                <ListItemText primary="Cancel booking" secondary="This action cannot be reverted" />
              </ListItem>
              <ListItem component="button" button classes={{ root: classes.actionButton }}>
                <ListItemText primary="Test" secondary="Some action should be placed here" />
              </ListItem>
            </MaterialList>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  {
    onGetBookingData: getBookingData,
    onCancelBooking: cancelBooking,
    onEditBooking: editBooking,
  },
)(SingleBooking);

SingleBooking.propTypes = {
  onGetBookingData: PropTypes.func.isRequired,
  onCancelBooking: PropTypes.func.isRequired,
  onEditBooking: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
