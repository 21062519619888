import { SET_BOOKINGS, SET_WEBINAR_LIST } from '../types';
import { requestHandler } from '../../routes';
import { setSnackBar } from './other';

export const setWebinarList = (data) => ({
  type: SET_WEBINAR_LIST,
  data,
});

export const getWebinarList = () => async (dispatch) => {
  const data = await requestHandler.send({
    url: '/api/webinar/get',
    method: 'GET',
  });

  dispatch(setWebinarList(data));
  return data;
};

export const getWebinarPage = (id) => async () => {
  const data = await requestHandler.send({
    url: `/api/webinar/get/${id}`,
    method: 'GET',
  });

  console.log(data);
  return data;
};


export const updateWebinar = (id, data) => async () => {
  const result = await requestHandler.send({
    url: `/api/webinar/update/${id}`,
    method: 'PATCH',
    data,
  });

  console.log(result);
  return result;
};

export const createZoomMeeting = (id) => async () => {
  const data = await requestHandler.send({
    url: `/api/webinar/createZoomMeeting/${id}`,
    method: 'GET',
  });

  console.log(data);
  return data;
};


export const updateZoomMeeting = (id) => async () => {
  const data = await requestHandler.send({
    url: `/api/webinar/updateZoomMeeting/${id}`,
    method: 'PATCH',
  });

  console.log(data);
  return data;
};

export const deleteZoomMeeting = (id) => async () => {
  const data = await requestHandler.send({
    url: `/api/webinar/deleteZoomMeeting/${id}`,
    method: 'DELETE',
  });

  console.log(data);
  return data;
};

export const sendZoomInvites = (id) => async () => {
  const data = await requestHandler.send({
    url: `/api/webinar/sendInvites/${id}`,
    method: 'GET',
  });

  console.log(data);
  return data;
};
