import {SET_BOOKINGS} from '../types';
import { requestHandler } from '../../routes';
import {setSnackBar} from "./other";

export const setBookings = (data) => ({
  type: SET_BOOKINGS,
  data,
});

export const getBookings = () => async (dispatch) => {
  const data = await requestHandler.send({
    url: '/api/admin/bookings-list',
    method: 'GET',
  });

  dispatch(setBookings(data));
  return data;
};

export const getBookingData = (id) => () => {
  return  requestHandler.send({
    url: `/api/admin/booking/${id}`,
    method: 'GET',
  });
};

export const cancelBooking = (id) => async (dispatch) => {
  try {
    const data = await requestHandler.send({
      url: `/api/admin/cancel-booking/${id}`,
      method: 'DELETE',
    });

    dispatch(setSnackBar({ open: true, message: 'Successfully canceled!', severity: 'success' }));
    dispatch(setBookings(data));
    return data;

  } catch (e) {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong', severity: 'error' }));
    return []
  }
};

export const editBooking = (id, changeData) => async (dispatch) => {
  try {
    const data = await requestHandler.send({
      url: `/api/admin/booking/${id}`,
      method: 'PUT',
      data: changeData,
    });

    dispatch(setSnackBar({ open: true, message: 'Successfully saved!', severity: 'success' }));
    return data;

  } catch (e) {
    dispatch(setSnackBar({ open: true, message: 'Something went wrong', severity: 'error' }));
    return null;
  }
};
