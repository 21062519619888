/* eslint-disable react/jsx-no-duplicate-props */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import FormInput from '../../../components/FormInput';
import AutocompleteInput from '../../../components/AutocompleteInput';

const min = 0;
const max = 10;

const normalize = (value) => (value >= min ? value < max ? value : 10 : 0);


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  flex: {
    display: 'flex',
    marginBottom: 15,
    marginTop: 15,
  },
}));

const HotelGroupForm = ({ handleSubmit, hotels }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={6} justify="flex-start" alignItems="center" className={classes.flex}>
          <Field
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Name"
            name="name"
            autoFocus
            component={FormInput}
          />
        </Grid>
        <Grid item xs={6} justify="flex-end" alignItems="center" className={classes.flex}>
          <Field
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="ratio"
            label="Markup"
            autoFocus
            type="number"
            aria-describedby="standard-weight-helper-text"
            inputProps={{
              min,
              max,
              step: 0.1,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            component={FormInput}
            normalize={normalize}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            label="Reports"
            name="hotels"
            autoFocus
            data={hotels}
            component={AutocompleteInput}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} justify="flex-end" alignItems="center" className={classes.flex}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            SAVE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'HotelGroupForm',
})(HotelGroupForm);

HotelGroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hotels: PropTypes.array.isRequired,
};
