import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Prompt } from 'react-router-dom';
import FormInput from '../../../components/FormInput';
import FormDateTimePicker from '../../../components/FormDateTimePicker';
import AutocompleteInput from '../../../components/AutocompleteInput';

const WebinarForm = ({
  handleSubmit, agents, initialValues, dirty, setIsDirty,
}) => {
  useEffect(() => {
    setIsDirty(dirty);
  });
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: 15,
      }}
    >
      <Grid container item xs={12}>
        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Title"
          name="topic"
          autoFocus
          component={FormInput}
        />
        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Description"
          name="description"
          component={FormInput}
        />
        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Time slots"
          name="time"
          component={FormInput}
        />
        <Grid item container xs={12} alignItems="stretch">
          <Field
            variant="outlined"
            margin="normal"
            fullWidth
            label="Agents"
            name="invitedUsers"
            data={agents}
            component={AutocompleteInput}
          />
        </Grid>

        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Date"
          name="date"
          disablePast
          invalidDateMessage={null}
          component={FormDateTimePicker}
        />
        <Field
          variant="outlined"
          margin="normal"
          fullWidth
          label="Duration (minutes)"
          name="duration"
          type="number"
          component={FormInput}
        />
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </Grid>
        <Prompt
          when={dirty}
          message="Are you sure, you have unsaved changed"
        />
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'WebinarForm',
  enableReinitialize: true,
  validate(values) {
    const errors = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }

    return errors;
  },
})(WebinarForm);
