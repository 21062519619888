import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';


const BreadcrumbsCustom = ({ pathname, classes }) => {
  const pathe = pathname.split('/');
  pathe.shift();
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      {pathe.map((path, i, curr) => {
        const isCurrent = curr[curr.length - 1] === path;
        const link = `/${[...pathe].slice(0, i+1).join('/')}`;

        if (isCurrent) {
          return (
            <Typography key={link} className={classes.capitalise}>{path.replace('-', ' ')}</Typography>
          );
        }
        return (
          <Link
            to={link}
            key={link}
            className={classes.capitalise}
          >
            {path.replace('-', ' ')}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsCustom;
