import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import HouseIcon from '@material-ui/icons/House';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import QueueIcon from '@material-ui/icons/Queue';
import TelegramIcon from '@material-ui/icons/Telegram';
import VideoCallIcon from '@material-ui/icons/VideoCall';

import {
  ADD_HOTELS,
  AGENTS, BOOKING, CREATE_USER, DASHBOARD, HOTEL_GROUPS, HOTELS, REPORTS, TELEGRAM, WEBINAR_REQUESTS,
} from '../routes/routes';

export const MainMenuItems = [
  {
    title: 'dashboard',
    link: DASHBOARD,
    Icon: DashboardIcon,
  },
  {
    title: 'agents',
    link: AGENTS,
    Icon: PeopleIcon,
  },
  {
    title: 'hotels',
    link: HOTELS,
    Icon: HouseIcon,
  },
  {
    title: 'Hotel Markups',
    link: HOTEL_GROUPS,
    Icon: LayersIcon,
  },
  {
    title: 'Newsletters reports',
    link: REPORTS,
    Icon: BarChartIcon,
  },
  {
    title: 'Booking',
    link: BOOKING,
    Icon: CardTravelIcon,
  },
  {
    title: 'Create user',
    link: CREATE_USER,
    Icon: PersonAddIcon,
  },
  {
    title: 'Upload hotels',
    link: ADD_HOTELS,
    Icon: QueueIcon,
  },
  {
    title: 'Telegram',
    link: TELEGRAM,
    Icon: TelegramIcon,
  },
  {
    title: 'Webinar requests',
    link: WEBINAR_REQUESTS,
    Icon: VideoCallIcon,
  },
];
